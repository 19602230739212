<template>
<div>
  <ul class="gallery">
    <li>
      <video controls src="https://video.pearvideo.com/mp4/adshort/20210801/cont-1737235-15735188_adpkg-ad_hd.mp4"/>
    </li>
    <li>
      <img src="https://source.unsplash.com/random?greece" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?dance" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?usa" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?spain" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?italy" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?denmark" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?switzerland" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?egypt" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?germany" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random?cuba" alt="">
    </li>
    <li>
      <img src="https://source.unsplash.com/random" alt="">
    </li>
  </ul>
  <button>Add items</button>
</div>
</template>

import aliplayer from '@/components/video_play/index'
<script>
export default {
  name: "test_Index7",
  mounted() {
    let gallery = document.querySelector('ul');
    let add = document.querySelector('button');

    add.addEventListener('click', () => {
      let li = document.createElement('li');
      li.innerHTML = `<img src="https://source.unsplash.com/random/${Math.random()}" alt="">`;
      gallery.append(li);
    });
  }
}
</script>

<style scoped>
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 150px 200px;
  gap: 1rem;
}

li:nth-child(2) {
  grid-row: span 2;
}

li:nth-child(4) {
  grid-row: span 3;
}



/*
    👇 Other than the layout
*/

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' viewBox='0 0 700 700' width='700' height='700'%3E%3Cdefs%3E%3ClinearGradient gradientTransform='rotate(230, 0.5, 0.5)' x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='ffflux-gradient'%3E%3Cstop stop-color='%23FD6C13' stop-opacity='1' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23E63E00' stop-opacity='1' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3Cfilter id='ffflux-filter' x='-20%25' y='-20%25' width='140%25' height='140%25' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.001 0.001' numOctaves='2' seed='221' stitchTiles='stitch' x='0%25' y='0%25' width='100%25' height='100%25' result='turbulence'%3E%3C/feTurbulence%3E%3CfeGaussianBlur stdDeviation='0 0' x='0%25' y='0%25' width='100%25' height='100%25' in='turbulence' edgeMode='duplicate' result='blur'%3E%3C/feGaussianBlur%3E%3CfeBlend mode='color-dodge' x='0%25' y='0%25' width='100%25' height='100%25' in='SourceGraphic' in2='blur' result='blend'%3E%3C/feBlend%3E%3C/filter%3E%3C/defs%3E%3Crect width='700' height='700' fill='url(%23ffflux-gradient)' filter='url(%23ffflux-filter)'%3E%3C/rect%3E%3C/svg%3E");
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  min-width: 0;
  padding: 14px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow:
      0.3px 0.5px 0.7px hsl(23deg 93% 31% / 0.36),
      0.8px 1.6px 2px -0.8px hsl(23deg 93% 31% / 0.36),
      2.1px 4.1px 5.2px -1.7px hsl(23deg 93% 31% / 0.36),
      5px 10px 12.6px -2.5px hsl(23deg 93% 31% / 0.36);
}

img,video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


button {
  display: block;
  width: fit-content;
  padding: 8px 16px;
  border: 0;
  border-radius: 3px;
  margin-block-start: 1rem;
  background-color: #fff;
  color: #222;
  font: 14px/1.6 system-ui;
}
</style>